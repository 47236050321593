import './Top.css';

import axios from "axios"
import ReactMarkdown from "react-markdown";
import {useState, useMemo} from "react"

import TermsOfService from '../component/TermsOfService';
import Fields from '../component/Fields';
import {Selector} from '../component/Selector';
import {TitleBar, SubTitleBar} from '../component/TitleBar';
import {Button, SimpleButton} from '../component/Button';
import ProgressBar from '../component/ProgressBar';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

import Questions from '../contents/questions.json';
import QuestionPage from '../contents/question_page.json';
import Explain_Top from '../contents/explain_top.md';

import { allIncludes, personInputs } from '../utils/Calculator';
import { useParameterContext } from '../utils/States';


function Top() {
  let { state, dispatch } = useParameterContext();

  let [ page, setPage ] = useState(0);
  let [ tos, setTos ] = useState(false);

  const resetPerson = () => {
    dispatch( { "type": "delete_person", "payload": {} } )
  }
  const clickHandler = () => {
    window.location.href='/userInfo'
  }
  const [text, setText] = useState()
  fetch(Explain_Top).then(res => res.text()).then(txt => setText(txt));

  let content = [];
  let button = [];

  const page_size = QuestionPage.pages.length;

  switch(page){
    case 0:
      content = <section className="Top-section">
        <div className="Top-top-body">
          <h3>24問の簡単なアンケートで、貴社のアジャイル成熟度を診断できます。</h3>
          <ReactMarkdown children={text}/><br/>
        </div>
      </section>
      button = <section className="Top-button">
        <SimpleButton onClick={() => {setPage(page+1); window.scrollTo(0, 0);}}>次へ</SimpleButton>
      </section>
      break;
    case 1:
      content = <section className="Top-section">
        <SubTitleBar>利用規約</SubTitleBar>
        <TermsOfService />
        <label className="Top-tos-label" onChange={() => setTos(!tos)}>
          <input className="Top-tos-checkbox" type="checkbox" id="tos" name="terms_of_service" checked={tos} onChange={e => {}} required/>
          <div className="Top-tos-text">利用規約に同意する</div>
        </label>
      </section>
      button = <section className="Top-button">
        <SimpleButton disabled={!tos} onClick={() => {resetPerson(); setPage(page+1); window.scrollTo(0, 0);}}>次へ</SimpleButton>
      </section>
      break;
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
      const next_button_disable = !allIncludes(Object.keys(state.answer), QuestionPage.pages[page-2].tags)
      content = <section className="Top-section">
        <ProgressBar now={page-2+1} base={page_size}/>
        <SubTitleBar></SubTitleBar>
        <Selector qs={QuestionPage.pages[page-2].tags} />
      </section>
      button = <section className="Top-button">
        <SimpleButton onClick={() => {setPage(page-1); window.scrollTo(0, 0);}}>戻る</SimpleButton>
        {
          (page-2+1 === page_size)
          ? <SimpleButton onClick={clickHandler} disabled={next_button_disable}>結果表示</SimpleButton>
          : <SimpleButton disabled={next_button_disable} onClick={() => {setPage(page+1); window.scrollTo(0, 0);}}>次へ</SimpleButton>
        }
        { (page-2+1 === page_size)
         ? <div>※診断結果を見るには、お客様情報を入力してください</div>
         : <div></div>
         }</section>
      //annotation = (page-2+1 === page_size) ? <div>お客様情報入力が必要です</div>:<div></div>;
      break;
    default:
      setPage(0)
      //console.log("setPage by error", page)
      break;
  }

  return (
    <div className="Top">
      <Header />
      <TitleBar>アジャイル成熟度診断</TitleBar>
      {content}
      {button}
       <Footer />
    </div>
  );
}

export default Top;