import './Radio.css';

import { useParameterContext } from '../utils/States';

function Radio({content}){
  let { state, dispatch } = useParameterContext();

  const onChangeValue = data => {
    // console.log(data.target.name, data.target.value);
    dispatch( { "type": "add_answer", "payload": {[data.target.name]: Number(data.target.value)} } );
    // console.log(state.answer);
  }

  const items = []
  // items.push(<h4 className="Radio-category">{content.category}</h4>)
  items.push(<h4 className="Radio-question">{content.question}</h4>)
  //console.log(content.annotation)
  if (content.remarks !== ""){
    items.push(<div className="Radio-remarks">{content.remarks}</div>)
  }
  for (const i of content.answer){
    items.push(<li><label className="Radio-label" onChange={onChangeValue}>
        <input className="Radio-input-radio" type="radio" name={content.tag} value={i.number} 
        checked={state.answer[content.tag]===i.number} onChange={e => {}}
        />
        <div className="Radio-answertext">{i.text}</div>
      </label></li>)
  }
  return <ul className="Radio-ul" key={content.tag}>{items}</ul>
}

export default Radio;