import './Top.css';
import axios from "axios"
import Fields from '../component/Fields';
import { TitleBar, SubTitleBar } from '../component/TitleBar';
import { Button, SimpleButton } from '../component/Button';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { personInputs } from '../utils/Calculator';
import { useParameterContext } from '../utils/States';

function UserInfo() {
    const isProduction = process.env.REACT_APP_ENV === "production"
    const API_TARGET = isProduction ? process.env.REACT_APP_API_POST_TARGET_PROD : process.env.REACT_APP_API_POST_TARGET_DEV;
    //console.log(API_TARGET)
    let { state, dispatch } = useParameterContext();
    
    const resetPerson = () => {
      dispatch( { "type": "delete_person", "payload": {} } )
    }
    
    const clickHandler = async() => {
      let person = state.person;
      let header = {
        "Cache-Control": "no-cache",
      }
      if(!(person.email === null || person.email === undefined || person.email === "") ){
        //const POST_TARGET = process.env.REACT_APP_POST_TARGET;
        await axios.post(API_TARGET, {
          "first_name": person.first_name || "",
          "last_name": person.last_name || "",
          "company": {
            "name": person.company_name || "",
            "category": person.company_category || "",
            "grade": person.company_grade || ""
          },
          "email": person.email || "",
          "phone": person.phone || "",
          "answers": state.answer
        },
        {headers: header}
        )
      }
      window.location.href='/result'
      //resetPerson();
    }
    
    let content = [];
    let button = [];
  
    const person_button_disable = !personInputs(state.person);
    content = <section className="Top-section">
      <SubTitleBar>お客様情報入力</SubTitleBar>
      <Fields />
    </section>
    button = <section className="Top-button">
      <SimpleButton disabled={person_button_disable} onClick={clickHandler}>結果表示</SimpleButton>
      {person_button_disable &&
      <div className="Input-error">必須項目が未入力です。</div>}
    </section>
      return (
        <div className="Top">
          <Header />
          <TitleBar>アジャイル成熟度診断</TitleBar>
          {content}
          {button}
          <Footer />
        </div>
      );
}

export default UserInfo;