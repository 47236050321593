import './Result.css';
import { useState, useMemo, useEffect } from "react";
import RadarChart from '../component/RadarChart';
import {GradeTable} from '../component/Table';
import {TitleBar, SubTitleBar, ContentTitleBar} from '../component/TitleBar';
import {Button, ButtonLarge, ExternalButtonLarge} from '../component/Button';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { useParameterContext } from '../utils/States';
import { getCategoryScore, getMaturityScore} from '../utils/Calculator';
import Advice from '../contents/advice.json';

function Result() {
  let { state, dispatch } = useParameterContext();

  const resetPerson = () => {
    dispatch( { "type": "delete_person", "payload": {} } )
  }
  useEffect(() => {
    resetPerson()
  },[])

  const fileDownloadFromUrl = async () => {
    try {
      const response = await fetch("/documents/agile_assessment_leaflet.pdf", { method: "GET" });
      if (response.ok) {
        const contentType = response.headers.get("Content-Type");
        //console.log(contentType); // デバッグ用

        if (contentType === "application/pdf") {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Ridgelinez Agile成熟度診断_リーフレット.pdf";
          document.body.appendChild(a); // Append to body to make it work in Firefox
          a.click();
          document.body.removeChild(a); // Clean up
          window.URL.revokeObjectURL(url); // Clean up
        } else {
          //console.error("The fetched file is not a PDF.");
        }
      } else {
        //console.error("Failed to fetch the file.");
      }
      
    } catch (error) {
      //console.error("An error occurred:", error);
    }
  };
  const downloadClickHandler = () => {
    fileDownloadFromUrl();
  }
  const backClickHandler = () => {
    dispatch( { "type": "delete_answer", "payload": {} } );
  }

  const data_base ={
    labels: ['柔軟性・適応力', '学習と成長', '顧客との関係性', 'フィードバック活用',  'ツール活用', 'アジャイル開発'],
    result: getCategoryScore(state.answer),
    basedata: [3, 3, 3, 3, 3, 3],
  }

  const maturityScore = getMaturityScore(state.answer)
  const maturityLevel = useMemo(() =>Advice.total.find(i => (i.point >  maturityScore)), [maturityScore])

  return (
    <div>
      <div>
      <Header />
      <TitleBar>アジャイル成熟度診断結果</TitleBar>
      <section className="Result-section">
      <br/>
      <h2>貴組織のアジャイル成熟度スコアは「{maturityScore}点」「{maturityLevel.company}」になります</h2>
        <br/>
        <div className="Result-dxscore-result">
          <div>
            <ContentTitleBar >アジャイル成熟度のグレード</ContentTitleBar>
            <div className="Result-dxscore-table"><GradeTable average={maturityScore}/></div>
          </div>
          <div align="center">
            <ContentTitleBar >アジャイル成熟度診断結果</ContentTitleBar>
            <div className="Result-dxscore-rader">
              <RadarChart result={data_base} /></div>
          </div>
        </div>
        <div className="Result-dxscore-body">{maturityLevel.body}</div>
        <div className="Result-information-body">
          　アジャイルの導入や拡大における課題、また成功要因についてのリーフレットをダウンロードいただけます。<br/>
        </div>
          <div>
            <ButtonLarge onClick={downloadClickHandler}>リーフレットダウンロード</ButtonLarge>
          </div>
          <div>
            <Button to="/" onClick={backClickHandler} main={false}>診断をやり直す</Button>
          </div>
      </section>
      </div>
      <div className="Result-fixed-area">
        <TitleBar>お問い合わせ</TitleBar>
        <section className="Result-section">
          <div className="Result-information-body">
            　Ridgelinezは、アジャイル組織変革開発の専門知識を有するコンサルタントが企業におけるアジャイル推進の課題を可視化し、生成AIを活用した組織のアジャイル成熟度診断・課題の抽出を行うなど、アジャイル開発チームの立上げから迅速な組織変革施策の実行展開までを一貫してEnd to Endで支援します。<br/><br/>
            　Ridgelinezのご支援に興味をお持ちの方、ご相談をご希望の方は以下よりお問い合わせください。
          </div>
          <ExternalButtonLarge to="https://www.ridgelinez.com/contact/form/service/">お問い合わせ</ExternalButtonLarge>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Result;