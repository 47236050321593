import './InputField.css';

import { useParameterContext } from '../utils/States';

function InputFieldMust({must}){
  return (must==="true") ? <div className="InputField-must">必須</div> : <div></div>;
}


export function InputField({title, id, example, must="false", maxLength}) {
  let { state, dispatch } = useParameterContext();
  
  const onChangeValue = data => {
    // console.log(data.target.name, data.target.value);
    dispatch( { "type": "add_person", "payload": {[data.target.name]: data.target.value} } );
    // console.log(state.person);
  }
  
  return (<div className="InputField">
      <div className="InputField-title"><InputFieldMust must={must}/>{title}</div>
      <input className="InputField-input"
        type="text"
        id={id}
        name={id}
        maxLength={maxLength}
        onChange={onChangeValue}
        placeholder={example}/>
    </div>
  );
}

export function EmailField({title, id, example, must="false", maxLength}) {
  let { state, dispatch } = useParameterContext();
  
  const onChangeValue = data => {
    // console.log(data.target.name, data.target.value);
    dispatch( { "type": "add_person", "payload": {[data.target.name]: data.target.value} } );
    // console.log(state.person);
  }
  
  return (<div className="InputField">
      <div className="InputField-title"><InputFieldMust must={must}/>{title}</div>
      <input className="InputField-input"
        type="email"
        id={id}
        name={id}
        maxLength={maxLength}
        placeholder={example}
        onChange={onChangeValue}
        pattern="/^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/" />
    </div>
  );
}

export function PhoneField({title, id, example, must="false", maxLength}) {
  let { state, dispatch } = useParameterContext();
  
  const onChangeValue = data => {
    // console.log(data.target.name, data.target.value);
    dispatch( { "type": "add_person", "payload": {[data.target.name]: data.target.value} } );
    // console.log(state.person);
  }
  
  return (<div className="InputField">
      <div className="InputField-title"><InputFieldMust must={must}/>{title}</div>
      <input className="InputField-input"
        type="tel"
        id={id}
        name={id}
        maxLength={maxLength}
        placeholder={example}
        onChange={onChangeValue}
        pattern="/^0\d{1,3}-\d{2,4}-\d{3,4}$/" />
    </div>
  );
}

export function PulldownField({title, id, example, must="false", list}) {
  let { state, dispatch } = useParameterContext();
  
  const onChangeValue = data => {
    // console.log(data.target.name, data.target.value);
    dispatch( { "type": "add_person", "payload": {[data.target.name]: data.target.value} } );
    // console.log(state.person);
  }
  
  const items=[];
  for(const i of list){
    items.push(<option value={i} key={i}>{i}</option>)
  }

  return (<div className="InputField">
      <div className="InputField-title"><InputFieldMust must={must}/>{title}</div>
      <select className="InputField-input"
        type="select"
        id={id}
        name={id}
        onChange={onChangeValue}
        placeholder={example}>
        {items}
      </select>
    </div>
  );
}
