import { createContext, useReducer, useContext, useEffect } from 'react';
import { initValue } from './Parameters';

const Parameters = createContext();

/////////////// scores ///////////////
export function useParameterContext() {
  return useContext(Parameters);
}

function Reducer(state, action){
  switch(action.type){
    case "add_answer":
      return {
        "answer": {...state.answer, ...action.payload},
        "person": state.person
      };
    case "add_person":
      return {
        "answer": state.answer,
        "person": {...state.person, ...action.payload}
      };
      case "delete_person":
        return {
          "answer": state.answer,
          "person": {}
        };
        case "delete_answer":
          return {
            "answer": {},
            "person": {}
          };
    default:
      return state;
  }
}

function init(initVal = initValue){
  let ret;
  if(sessionStorage.getItem("localState") === null){
    ret = initVal
  }else{
    ret = JSON.parse(sessionStorage.getItem("localState"))
  }
  return ret;
}

export function ParameterProvider({ children }) {
  const [state, dispatch] = useReducer(Reducer, initValue, init);

  useEffect(() => {
    sessionStorage.setItem("localState", JSON.stringify(state));
  }, [state]);

  return (
    <Parameters.Provider value={{state, dispatch}}>{children}</Parameters.Provider>
  );
}
