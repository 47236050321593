import './TermsOfService.css';
import TermsText from '../contents/terms_of_service.md';

import ReactMarkdown from "react-markdown";
import { useState } from "react";

function TermsOfService() {
  const [text, setText] = useState()
  fetch(TermsText).then(res => res.text()).then(txt => setText(txt));

  return (
    <div className="TermsOfService">
      <div className="TermsOfService-markdown">
        <ReactMarkdown children={text}/>
      </div>
    </div>
  );
}

export default TermsOfService;
