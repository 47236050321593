import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";

import Top from './pages/Top';
import Result from './pages/Result';
import UserInfo from './pages/UserInfo';
import NotFound from './pages/NotFound';
import { ParameterProvider } from './utils/States';

function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return children;
}

function App() {
  return (
    <div className="App">
      <ParameterProvider>
        <BrowserRouter>
          <ScrollToTop>
            <header className="App-header">
              <Routes>
                <Route index element={<Top />} />
                <Route path="result" element={<Result />} />
                <Route path="userInfo" element={<UserInfo />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </header>
          </ScrollToTop>
        </BrowserRouter>
      </ParameterProvider>
    </div>
  );
}

export default App;
