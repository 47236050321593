import "./Header.css"

import logo from './img/logo.webp'

function Header() {
  return (
    <div className="Header">
      <section className="Header-section">
        {/* <a herf="https://www.ridgelinez.com" target="_blank" rel="noopener noreferrer"> */}
          <img className="Header-logo" src={logo}/>
        {/* </a> */}
      </section>
    </div>
  );
}

export default Header;