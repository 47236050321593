import './Table.css';
import React from "react";

import Grades from '../contents/grade_table.json';

function TableHeader({header}){
  const items = []
  items.push(<th className="Table-rowcolumn-head" key={header.key}>{header.key}</th>)
  for (const h of header.value){
    items.push(<th className="Table-thead" key={h}>{h}</th>)
  }
  return <tr key={header.key}>
      {items}
  </tr>
}
function TableBody({body, main}){
  const items = []
  let classRowhead = "Table-rowhead"
  let classTbody = "Table-tbody"
  if(main){
    classRowhead = "Table-rowhead-main"
    classTbody = "Table-tbody-main"
  }
  items.push(<th className={classRowhead} key={body.key}>{body.key}</th>)
  for (const b of body.value){
    items.push(<th className={classTbody} key={b}>{b}</th>)
  }
  return <tr key={body.key}>
      {items}
  </tr>
}

export function GradeTable({average}) {
  const items = []
  let target = true
  for (const b of Grades.body){
    let main = false
    if(target && (average>=b.point)){
      main = true
      target = false
    }
    items.push(<TableBody body={b} main={main} key={b.key} />)
  }

  return (
    <table className="Table">
      <thead className="Table-thead">
        <TableHeader header={Grades.header} />
      </thead>
      <tbody>
        {items}
      </tbody>
    </table>
  );
}