import "./Footer.css"
// import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="Footer">
      <section className="Footer-section">
        © 2020-2024 Ridgelinez. All Rights Reserved.
      </section>
    </div>
  );
}

export default Footer;