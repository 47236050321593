import './Selector.css';
import Questions from '../contents/questions.json';
import Radio from './Radio';

export function Selector({qs}){
  const items = []
  const questions = Questions.contents.filter(content => {
    return ( qs.includes(content.tag) );
  });
  for (const content of questions){
    items.push(<div className="Selector-Radio"><Radio content={content}/></div>)
  }
  return (
    <div className="Selector" key="selector">
      {items}
    </div>
  );
}