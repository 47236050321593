import {x4XPoint, DxPoint, offerPoint, ansScore} from "./Parameters"

/////////////// scores ///////////////

function getNumOfQuestion(){
  const numOfQuestion = [0,0,0,0,0,0];
  for(const i of Object.keys(DxPoint)){
    for(const j in DxPoint[i]){
      numOfQuestion[j] += DxPoint[i][j]
    }
  }
  return numOfQuestion
}

//カテゴリースコア算出
export function getCategoryScore(answer){
  const numOfQuestion = getNumOfQuestion()

  const score = [0,0,0,0,0,0];

  for(const key of Object.keys(DxPoint)){
    for(const j in DxPoint[key]){
      score[j] += DxPoint[key][j]*ansScore[answer[key]];
    }
  }

  for(const i in numOfQuestion){
    score[i] = score[i] / numOfQuestion[i]
    //console.log("score" + i + " = score: " + score[i] + " = numOfQuestion: " + numOfQuestion[i])
  }
  return score;
}
//成熟度スコア算出
export function getMaturityScore(answer){
  const numOfQuestion = getNumOfQuestion()
  const score = [0,0,0,0,0,0];

  const numOfQuestionSum = numOfQuestion.reduce(function(sum, element){
    return sum += element;
  }, 0);
  //console.log("numOfQuestionSum: " + numOfQuestionSum)
  for(const key of Object.keys(DxPoint)){
    for(const j in DxPoint[key]){
      score[j] += DxPoint[key][j]*ansScore[answer[key]];
    }
  }
  const scoreSum = score.reduce(function(sum, element){
    return sum += element;
  }, 0);
  const maturityScore = Math.trunc(scoreSum/numOfQuestionSum * 25)
  return maturityScore;
}

/////////////// person ///////////////

export function personInputs(person){
  const EMAIL_REGEX = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

  let res = true;
  if((person.first_name === null    || person.first_name === undefined    || person.first_name === ""   )){res = false}
  if((person.last_name === null     || person.last_name === undefined     || person.last_name === ""    )){res = false}
  if((person.company_name === null  || person.company_name === undefined  || person.company_name === "" )){res = false}
  if((person.email === null         || person.email === undefined         || person.email === ""        )){res = false}
  if(!EMAIL_REGEX.test(person.email)){res = false}
  return res
}

/////////////// tags ///////////////

export function allIncludes(src, target){
  // src = ["Q1", "Q2", "Q3", "Q4", "Q5", "Q6", "Q7"]
  // target = ["Q6", "Q7", "Q8"]

  const include = src.filter(tag => {
    return ( target.includes(tag) );
  });

  return (target.length === include.length)
}