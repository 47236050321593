import './RadarChart.css';
import { Radar } from 'react-chartjs-2';
import React from 'react';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register( RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend );
const color={
  "Black": 'rgb(15, 32, 62)',
  "White": 'rgb(255, 255, 255)',
  "Main": 'rgb(28, 122, 172)',
  "Highlight": 'rgb(41, 181, 255)',
  "Gray": 'rgba(79, 91, 109)'
}

const options = {
  layout:{
    padding: {
      left: 0,
      right: 50,
      top: 0,
      bottom: 50
    }    
  },
  
  plugins:{
    legend: {
      display: false
    },
  },
  scales: {
    r:{
      grid: {
        color: color.Black,
      },
      angleLines: {
        color: color.Black
      },
      //軸のラベル
      pointLabels: {
        color: color.Black,
        font: {
          size: 16
        } 
      },
      beginAtZero: true,
      min: 0.6,
      max: 4,
      //目盛りのラベル
      ticks: {
        stepSize: 1,
        //ラベルの位置
        z: 0,
        padding: 3,
        //目盛りの文字
        color: color.Black,
        font: {
          size: 18
        },
        //目盛り背景
        backdropColor: color.White.replace(')', ', 0.85)'),
        display: false
        // showLabelBackdrop: false,
      }
    }
  }
}
function RadarChart({result}) {
  let data = {
    labels: ['柔軟性・適応力', '学習と成長', '顧客との関係性', 'フィードバック活用',  'ツール活用', 'アジャイル開発'],
    datasets: [
      {
        data: result.result,
        borderWidth: 5,
        borderColor: color.Main
      }
    ]
  }
  data.labels = result.labels;
  //data.datasets[0].data = result.result;
  // data.datasets[1].data = result.basedata;

  return (<div className="RadarChart-chart" >
    <Radar data={data} options={options}  />
  </div>
  );
}

export default RadarChart;