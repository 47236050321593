import './Fields.css';

import {InputField, EmailField, PhoneField, PulldownField} from './InputField';

function Fields() {

  return (
    <div className="Fields">
      <div className="Fields-name">
        <InputField title="姓" id="first_name" example="姓" must="true" maxLength="100"/>
        <InputField title="名" id="last_name" example="名" must="true" maxLength="100"/>
      </div>
      <EmailField title="eメール" id="email" example="example@email.com" must="true" maxLength="100"/>
      <PhoneField title="電話番号" id="phone" example="03-1234-5678" maxLength="20"/>
      <InputField title="法人名(会社名、学校名、団体名)" id="company_name" example="Ridgelinez株式会社" must="true" maxLength="100"/>
      <PulldownField title="業種" id="company_category" list={["", "金融・保険・証券", "IT関連ベンダー", "情報・通信業", "農林・水産業", "コンサルティング", "製造業", "建設・不動産業", "流通・卸・商社", "電気・ガス・水道", "運輸", "飲食店・宿泊", "医療・福祉", "広告・デザイン", "放送・出版・メディア", "教育", "官庁・協会・団体等", "その他"]}/>
      <PulldownField title="役職分類" id="company_grade" list={["", "経営層・役員クラス", "本部長クラス", "部長クラス", "課長クラス", "主任/係長クラス", "一般社員", "契約・派遣・委託社員"]}/>
    </div>
  );
}

export default Fields;
