export const x4XPoint = {
  "Q1":  [0, 1, 0, 2],
  "Q2":  [2, 0, 0, 1],
  "Q3":  [0, 1, 0, 2],
  "Q4":  [0, 0, 0, 2],
  "Q5":  [2, 0, 1, 0],
  "Q6":  [0, 0, 0, 2],
  "Q7":  [0, 2, 0, 1],
  "Q8":  [0, 2, 0, 1],
  "Q9":  [0, 1, 2, 0],
  "Q10": [2, 0, 0, 0],
  "Q11": [0, 2, 1, 0],
  "Q12": [0, 2, 0, 1],
  "Q13": [0, 2, 0, 0],
  "Q14": [2, 0, 0, 0],
  "Q15": [0, 1, 2, 0],
  "Q16": [0, 0, 2, 0],
  "Q17": [2, 0, 1, 0],
  "Q18": [2, 0, 1, 0],
  "Q19": [0, 0, 2, 0],
  "Q20": [0, 1, 2, 0],
  "Q21": [1, 0, 2, 0],
  "Q22": [0, 0, 0, 2],
  "Q23": [0, 0, 2, 0],
  "Q24": [1, 0, 0, 2],
  "Q25": [1, 0, 0, 2],
}

export const DxPoint = {
  "Q1":  [1, 0, 0, 0, 0, 0 ],
  "Q2":  [1, 0, 0, 0, 0, 0 ],
  "Q3":  [1, 0, 0, 0, 0, 0 ],
  "Q4":  [1, 0, 0, 0, 0, 0 ],
  "Q5":  [0, 1, 0, 0, 0, 0 ],
  "Q6":  [0, 1, 0, 0, 0, 0 ],
  "Q7":  [0, 1, 0, 0, 0, 0 ],
  "Q8":  [0, 1, 0, 0, 0, 0 ],
  "Q9":  [0, 1, 0, 0, 0, 0 ],
  "Q10": [0, 0, 1, 0, 0, 0 ],
  "Q11": [0, 0, 1, 0, 0, 0 ],
  "Q12": [0, 0, 1, 0, 0, 0 ],
  "Q13": [0, 0, 0, 1, 0, 0 ],
  "Q14": [0, 0, 0, 1, 0, 0 ],
  "Q15": [0, 0, 0, 1, 0 ,0 ],
  "Q16": [0, 0, 0, 1, 0 ,0 ],
  "Q17": [0, 0, 0, 0, 1 ,0 ],
  "Q18": [0, 0, 0, 0, 1 ,0 ],
  "Q19": [0, 0, 0, 0, 1 ,0 ],
  "Q20": [0, 0, 0, 0, 0, 1 ],
  "Q21": [0, 0, 0, 0, 0, 1 ],
  "Q22": [0, 0, 0, 0, 0, 1 ],
  "Q23": [0, 0, 0, 0, 0, 1 ],
  "Q24": [0, 0, 0, 0, 0, 1 ]
}


export const offerPoint={
  "Q1":  ["DX", "POT"],
  "Q2":  ["DX", "CX", "MC"],
  "Q3":  ["DX", "MC"],
  "Q4":  ["DX", "POT"],
  "Q5":  ["DX", "POT"],
  "Q6":  ["DX", "POT"],
  "Q7":  ["POT"],
  "Q8":  ["POT"],
  "Q9":  ["MC"],
  "Q10": ["BS", "MC", "EI"],
  "Q11": ["CX", "EI", "AI"],
  "Q12": ["POT"],
  "Q13": ["DX", "POT"],
  "Q14": ["CX", "POT"],
  "Q15": ["POT"],
  "Q16": ["POT", "AI"],
  "Q17": ["AI"],
  "Q18": ["AI"],
  "Q19": ["AI"],
  "Q20": ["EI"],
  "Q21": ["EI"],
  "Q22": ["RM"],
  "Q23": ["RM", "AI"],
  "Q24": ["BS"],
  "Q25": ["BS"],
}

// export const offerPoint4X={
//   "CX": "DX",
//   "EX": "DX",
//   "OX": "DX",
//   "MX": "DX"
// }

export const ansScore=  {"1": 1, "2":1.6, "3":2.6, "4":3.7 }

// const offerPointFrom4X=[
//   ["DX", "CX", "AI"],
//   ["DX", "POT", ],
//   ["DX", "OX2", "EI"],
//   ["DX", "BS", "MC"]
// ]

export const initValue={
  "answer": {},
  "person": {}
}
